import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"

const SecondPage = () => (
  <Layout>
    <SEO title="About" />
    <Container className="singleCol">
      <h1>About Howard Wire Cloth Company</h1>
      <p>
        You, our customers, are our most valuable asset, and we at Howard Wire Cloth Co. realize the importance of “Prompt Service” and quality products in meet your requirements.
      </p>
      <p>
        Since 1938, Howard Wire Cloth Co. has emphasized this service from our Bay Area facilities. With our ever expanding product lines, we are proud to offer complete screening services; including a wide range of woven and welded screed, perforated and expanded metal products. In addition, we offer manufacturing facilities for your custom requirements including slitting, stamping and fabrication.
      </p>
      <p>
        Feel free to call us on any of your requirements. Quality products, prompt service at fair prices are our standards of doing business.
      </p>
      <p>
        Sincerely,<br />
        Howard Wire Cloth Co.
      </p>
    </Container>
  </Layout>
)

export default SecondPage
